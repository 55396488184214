<template lang="pug">
  .view-docking-station-statistics
    .rides-statistics(v-loading="loadingStatistics")
      el-row.center.details-bold(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 15px;"
      )
        el-col(:span="8")
          span {{ $t('docking_stations.statistics.total_rides') }}

        el-col(:span="8")
          span {{ $t('docking_stations.statistics.rides_with_hardware_error')  }}
          .tooltip-container
            span.tooltiptext.break-word(v-html="formatHardwareErrorText")
            span.tooltip-trigger
              Tooltip(:tooltipText="formatHardwareErrorText")

        el-col(:span="8")
          span {{ $t('docking_stations.statistics.rides_with_customer_errors') }}
          .tooltip-container
            span.tooltiptext.break-word(v-html="formatOtherErrorsText")
            span.tooltip-trigger
              Tooltip(:tooltipText="formatOtherErrorsText")

      el-row.center(
        :gutter="50"
        type="flex"
        style="margin-top: 10px; margin-bottom: 25px"
      )   
        el-col(:span="8")
          span.break-word {{ statistics.no_of_total_rides }}

        el-col(:span="8")
          span.break-word {{ statistics.no_of_rides_closed_by_station_with_error }}

        el-col(:span="8")
          span.break-word {{ statistics.no_of_rides_with_other_errors }}

      el-row.center.details-bold(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 15px;"
      )
          
        el-col(:span="8")
          span {{ $t('docking_stations.statistics.completed_rides') }}

        el-col(:span="8")
          span {{ $t('docking_stations.statistics.started_rides') }}

        el-col(:span="8")
          span {{ $t('docking_stations.statistics.ended_rides') }}

      el-row.center(
        :gutter="50"
        type="flex"
        style="margin-top: 10px; margin-bottom: 25px"
      )

        el-col(:span="8")
          span.break-word {{ statistics.no_of_completed_rides }}

        el-col(:span="8")
          span.break-word {{ statistics.no_of_only_started_rides }}

        el-col(:span="8")
          span.break-word {{ statistics.no_of_only_ended_rides }}

    h3 {{ $t('docking_stations.rides_history') }}
    br
    .docking-station-details-modal        
      el-table(
        v-loading="loading"
        :data="rides"
        center
        header-cell-class-name='table-header'
        cell-class-name='pl-4'
        style='width: 100%; overflow-y: scroll;'
      )
        el-table-column(
          width="55"
        )
          template(slot-scope='scope')
            span {{ scope.$index + 1}}

        el-table-column(
          prop="created_at"
          :label="$t('docking_stations.created_at')"
        )        
          template(slot-scope='scope')
            span.bay_number {{ computeDateTimeFormat(scope.row.created_at) }}

        el-table-column(
          prop='start_docking_station_name'
          :label="$t('label.start_dock_end_dock')"
        )
          template(slot-scope='scope')
            span {{ scope.row.start_docking_station_name + "/ \n" }}
            span(v-if="!scope.row.start_docking_station_name") -

            span {{ scope.row.end_docking_station_name }}
            span(v-if="!scope.row.end_docking_station_name") -

        el-table-column(
          prop="status"
          :label="$t('docking_stations.status')"
        )
          template(slot-scope='scope')
            .status-wrapper(
              v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)"
            )
              .status
                span.oval.completed
                //- span {{ ridesStatuses[scope.row.status].message }}
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
              .status
                span.oval.cancelled
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
              .status
                span.oval.pending
                span {{ scope.row.status_object.label }}
        el-table-column(
          prop='bike_serial_number'
          :label="$t('common.bike_serial_number')"
        )
          template(slot-scope='scope') {{ scope.row.bike_serial_number }}
        el-table-column(
          prop='duration_seconds'
          :label="$t('common.ride_duration')"
        )
          template(slot-scope='scope') {{ computeRideDuration(scope.row.duration_seconds) }}
        el-table-column(
          prop="customer_email"
          :label="$t('common.last_customer')"
        )
          template(slot-scope='scope')
            span {{ scope.row.customer_email }}

      Pagination(
        :view="'rides'"
        :dockingStationId="dockingStation.id"
        @viewData="handleGetRides"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { ridesStatuses } from "@utils/statuses";
import { general } from "@src/services/dynamicViews/general";
import Pagination from "@components/_shared/Pagination";
import Tooltip from '@components/_shared/Tooltip';

export default {
  name: "RidesStatistics",
  mixins: [filterUtils, general],

  components: {
    Tooltip,
    Pagination,
  },

  data() {
    return {
      rides: [],
      statistics: {
        no_of_total_rides: 0,
        no_of_rides_closed_by_station_with_error: 0,
        no_of_rides_with_other_errors: 0,
        no_of_completed_rides: 0,
        no_of_only_ended_rides: 0,
        no_of_only_started_rides: 0
      },

      hardwareErrorText: "failed unlocking bike because of hardware error, station is not available",
      otherErrorsText: "failed because user did not pick up bike, canceled by admin, failed because user canceled before pick up bike, failed due to payment error",

      loadingStatistics: false,
      loading: false,

      inBetween,
      ridesStatuses,
    };
  },

  computed: {
    ...mapState("dockingStation", ["dockingStation"]),
    ...mapState("auth", ["user"]),

    formatHardwareErrorText(){
      return this.hardwareErrorText.split(',').join('<br>');
    },

    formatOtherErrorsText(){
      return this.otherErrorsText.split(',').join('<br>');
    }
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapActions("dockingStation", ["getDockingStationStatistics"]),

    handleGetRides(data) {
      this.rides = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    async handleGetCustomersStatistics() {
      this.loadingStatistics = true;
      this.statistics = await this.getDockingStationStatistics(this.dockingStation.id);
      this.loadingStatistics = false;
    },


  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  // border-style: solid;
  // border: 1px solid black;
  box-shadow: 1px 4px 5px 4px rgb(238, 238, 238);
  padding: 10px;
  padding-left: 15px; /* Add left padding to create a gap */
  position: absolute;
  z-index: 100;
  bottom: 95%; /* Position the tooltip above the text */
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.status-wrapper {
  display: flex;
  flex-direction: column;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
