import { render, staticRenderFns } from "./ViewCustomerTickets.vue?vue&type=template&id=7bca5353&scoped=true&lang=pug&"
import script from "./ViewCustomerTickets.vue?vue&type=script&lang=js&"
export * from "./ViewCustomerTickets.vue?vue&type=script&lang=js&"
import style0 from "./ViewCustomerTickets.vue?vue&type=style&index=0&id=7bca5353&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bca5353",
  null
  
)

export default component.exports