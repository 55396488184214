<template lang="pug">
  .customers-view
    h3 {{ $t('customers.customers') }}
    .statistics.d-flex.justify-space-evenly(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_customers_statistics'])"
      v-loading="loadingStatistics"
    )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('customers.statistics_restricted')"
        :value="statistics.no_of_restricted_customers"
        :fullWidth="false"
        :isMoneyStatistic="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('customers.statistics_unrestricted')"
        :value="statistics.no_of_unrestricted_customers"
        :fullWidth="false"
        :isMoneyStatistic="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('customers.statistics_total')"
        :value="statistics.no_of_total_clients"
        :fullWidth="false"
        :isMoneyStatistic="false"
        :justifyContent="'left'"
      )
    .filters
      .switch-buttons
        .switch
          el-switch(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['anonymized'])"
            v-model="anonymized"
            active-color="#2564F0"
            inactive-color="#dbe1fa"
            :active-text="$t('customers.anonymized')"
          )  
        .switch
          el-switch(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['restricted'])"
            v-model="restricted"
            active-color="#2564F0"
            inactive-color="#dbe1fa"
            :active-text="$t('customers.restricted_customers')"
          )
        .switch
          el-switch(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['non_confirmed'])"
            v-model="nonConfirmed"
            active-color="#2564F0"
            inactive-color="#dbe1fa"
            :active-text="$t('customers.nonConfirmed')"
          )  
      FilterCostCenterDropdown(
        v-model="selectedCostCenterId"
        @onChange="handleGetCustomersStatistics()"
      )
    .customers-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['by_keyword'])"
        )
      CustomersViewTable(
        v-loading="loadingTable"
        :customers="customers"
        @emitGetCustomersStatistics="handleGetCustomersStatistics"
      )
    Pagination(
      :view="'customers'"
      :search="search"
      :costCenterId="selectedCostCenterId"
      :getAnonymized="anonymized"
      :getRestricted="restricted"
      :getNonConfirmed="nonConfirmed"
      @viewData="handleGetCustomers"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { computeSelectedCostCenterId } from "@utils/generalUtils";
import Statistic from "@components/_shared/Statistic";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import Pagination from "@components/_shared/Pagination";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import CustomersViewTable from "@components/customers/CustomersViewTable"
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [general],

  components: {
    Statistic,
    SearchWithButtons,
    Pagination,
    FilterCostCenterDropdown,
    CustomersViewTable
  },

  data() {
    return {
      selectedCostCenterId: "",
      customers: [],
      search: "",
      statistics: {
        no_of_restricted_customers: 0,
        no_of_unrestricted_customers: 0,
        no_of_total_clients: 0,
      },

      anonymized: false,
      restricted: false,
      nonConfirmed: false,

      loadingStatistics: false,
      loadingTable: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapActions("customer", [
      "getCustomersStatistics",
    ]),

    handleGetCustomers(data) {
      this.customers = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading;
    },

    async handleGetCustomersStatistics() {
      if (!this.showItemDynamically(this.user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_customers_statistics'])) {
        return;
      }
      this.loadingStatistics = true;
      this.statistics = await this.getCustomersStatistics(
          computeSelectedCostCenterId(this.selectedCostCenterId)
      );
      this.loadingStatistics = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-pic {
  align-items: center;

  img {
    margin-right: 30px;
  }
}
.custom-border-switch .el-switch__core {
  border: 2px solid #000; /* Add your desired border style here */
  border-radius: 12px; /* Adjust border-radius if needed */
}

.custom-border-switch .el-switch__button {
  border: 2px solid #000 !important; /* Add border to the dot */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add shadow to the dot */
  background-color: #fff; /* Change background color of the dot */
}

/* Ensuring specificity and visibility of the dot (thumb) */
.custom-border-switch .el-switch__button::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 50%;
}
</style>
