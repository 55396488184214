<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='bikes'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
      prop='status'
      :label="$t('common.status')"
    )
      template(v-slot='scope')
        .status-wrapper.d-flex.align-items-center
          .switch(@click="handleSwitchBikeStatus(scope.row)")
            el-switch(
              :value="scope.row.status === 'available_in_station'"
              active-color="#13ce66"
              inactive-color="#ff4949"
            )
          span.status {{ bikesStatuses[scope.row.status].message }}

    el-table-column(
      prop='serial_number'
      :label="$t('common.serial_number')"
    )
    el-table-column(
      prop='docking_station_id'
      :label="$t('common.docking_station')"
    )
      template(v-slot='scope')
        span(@click="goToWIthId('docking_stations_list', scope.row.docking_station.id)")
        span.text-underline.cursor-pointer(v-if="scope.row.docking_station"
          @click="handleQrCodeClick(scope.row.docking_station)"
        ) {{ scope.row.docking_station.serial_number + '-' + (scope.row.bay && scope.row.bay.number || '0') }}
        span(v-else) -

    el-table-column(
      prop='scope.row.franchise && scope.row.franchise.name'
      :label="$t('common.cost_center_name')"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(@click="goToWIthId('cost_centers', '')") {{ scope.row.cost_center && scope.row.cost_center.name }}
    el-table-column(
      prop='bike_type'
      :label="$t('common.tracker')"
    )
      template(v-slot='scope')
        span(v-if="scope.row.tracker") {{ scope.row.tracker.name }}
        span(v-else) -
    el-table-column(
      prop='last_customer'
      :label="$t('common.last_customer')"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(
          v-if="scope.row.last_customer"
          @click="openInNewTab(scope.row.last_customer.id)"
        ) {{ scope.row.last_customer.first_name + ' ' + scope.row.last_customer.last_name }}
        span(v-else) -
    el-table-column(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance'])"
      prop='next_maintenance_at'
      :label="$t('label.next_maintenance')"
    )
      template(v-slot='scope')
        span(v-if="!ifMaintenancesToday(scope.row.next_maintenance_at) && scope.row.status !== 'under_maintenance'") {{ computeDateFormat(scope.row.next_maintenance_at) }}
        button.begin-maintenance-btn.cursor-pointer(
          v-if="ifMaintenancesToday(scope.row.next_maintenance_at) && scope.row.status !== 'under_maintenance'"
          @click="handleStartMaintenance(scope.row)"
          :disabled="scope.row.status === 'in_ride'"
          :class="{'disabled-btn ': scope.row.status === 'in_ride'}"
        ) {{ $t('bikes.start_maintenance_table_btn') }}
        button.begin-maintenance-btn.cursor-pointer(
          v-if="scope.row.status === 'under_maintenance'"
          @click="handleStartMaintenance(scope.row)"
          :class="{'disabled-btn ': scope.row.status === 'in_ride'}"
          :disabled="scope.row.status === 'in_ride'"
        ) {{ $t('bikes.ongoing_maintenance_table_btn') }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(v-slot='scope')
        .grid-buttons
          img.cursor-pointer.ml-1(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show'])"
            src="@assets/preview.svg"
            @click="handleViewBikeDetails(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show']) && hideIcon()"
            src="@assets/actions/edit.svg"
            @click="handleOpenEditBikeDetails(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="scope.row.can_be_deleted && hideIcon()"
            src="@assets/actions/delete.svg"
            @click="handleDeleteBikeDialog(scope.row)"
          )
          img.cursor-pointer.ml-1(
            src="@assets/actions/fix.svg"
            v-if=`scope.row.status !== 'in_ride' && (showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance']) &&
                  showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status']))
                `
            @click="handleFixBike(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="scope.row.dockingStationID && handleComputeUnlock(scope.row) && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
            src="@assets/actions/unlock.svg"
            @click="handleUnlockBike(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="['previously_stolen_but_now_returned'].includes(scope.row.status)"
            src="@assets/actions/found.svg"
            @click="handleFoundBike(scope.row)"
          )
          // TODO: dont forget about this
          //span.unlock-warning.cursor-pointer(
          //  v-if="showUnlockWarning.find(el => el[0] === scope.row.id)"
          //  @click="openUnlockWarning(showUnlockWarning.find(el => el[0] === scope.row.id))"
          //)
          //  i.el-icon-warning
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  filterUtils,
  inBetween,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { bikesStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "BikesViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    bikes: {
      type: Array,
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      bikesStatuses,
      inBetween,
      trimTableField,
      dynamicViewsConstants,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteBike();
    },
  },

  methods: {
    ...mapActions("bike", [
      "getBike",
      "deleteBike",
      "switchBikeStatus",
      "startMaintenance",
      "finishMaintenance",
    ]),

    ...mapMutations("dockingStation", ["setQrCode"]),

    ...mapMutations("dialog", [
      "showViewBikeDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "showStartBikeMaintenanceDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
      "showEditBikeDetailsDialog",
      "showQrCodeDialog",
    ]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapActions("dockingStation", ["getDock"]),

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    ifMaintenancesToday(date) {
      if (date) {
        const maintenanceDate = new Date(date).getTime();
        return !!(
          this.$moment(maintenanceDate).isSame(new Date(), "day") ||
          maintenanceDate < new Date().getTime()
        );
      } else {
        return null;
      }
    },

    getCurrentDateAndTime() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let hours = today.getHours();
      let seconds = today.getSeconds();
      let yyyy = today.getFullYear() + " " + hours + ":" + seconds;

      return (today = mm + "/" + dd + "/" + yyyy);
    },

    async handleSwitchBikeStatus(bike) {
      if (bike.status === "available_in_station") {
        let event_name =
          bike.status === "available_in_station"
            ? "make_unavailable"
            : "make_available";

        let params = {
          id: bike.id,
          event_name,
        };
        await this.switchBikeStatus(params);
        this.setShouldFetchBikes(true);
      } else if (bike.status === "under_maintenance") {
        let params = {
          id: bike.id,
          maintenance_status: "completed",
          next_maintenance_at: this.computeDateFormat(
            this.getCurrentDateAndTime()
          ),
          maintenance_description: "Completed",
        };

        await this.finishMaintenance(params).then(() => {
          let switchParams = {
            id: bike.id,
            event_name: "make_available",
          };
          this.switchBikeStatus(switchParams);
          this.setShouldFetchBikes(true);
        });
      } else if (bike.status === "unavailable") {
        if(bike.docking_station) {
          let dockStationObject = await this.getDock(bike.docking_station.id);

          if (dockStationObject.status !== "under_maintenance") {
            //make sure that the dock station is available first
            let event_name = "make_available";

            let params = {
              id: bike.id,
              event_name,
            };
            await this.switchBikeStatus(params);
            this.setShouldFetchBikes(true);
          } else {
            return this.$message({
              type: "error",
              message: this.$t("dialogs.bikes.dock_station_not_available"),
            });
          }
        } else {
          return this.$message({
          type: "error",
          message: this.$t("dialogs.bikes.bike_not_in_docking_station"),
          });
        }
      }
    },

    handleStartMaintenance(bike) {
      if (bike.status === "available_in_station") {
        this.showStartBikeMaintenanceDialog();
        this.setBike(bike);
      } else if (bike.status === "under_maintenance") {
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
      } else {
        this.startMaintenance(bike.id).then(() => {
          this.setBike(bike);
          this.showViewBikeMaintenanceDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },

    handleFixBike(bike) {
      if (bike.status === "available_in_station") {
        this.showStartBikeMaintenanceDialog();
        this.setBike(bike);
        return;
      }
      if (bike.status === "under_maintenance") {
        // if someone is already working on the bike
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
      } else {
        // set the bike on Ongoing maintenance
        this.startMaintenance(bike.id).then(() => {
          this.setBike(bike);
          this.showViewBikeMaintenanceDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },

    handleUnlockBike() {
      //TODO: to be handled when server is ready
    },

    handleFoundBike() {
      //TODO: to be handled when server is ready
    },

    handleDeleteBike() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteBike(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteBikeDialog(bike) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("bikes.validations.delete_title")} ${
          bike.serial_number
        }`,
        dialogMessage: this.$t("bikes.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(bike);
    },

    async handleViewBikeDetails(bike) {
      const response = await this.getBike(bike.id);
      this.showViewBikeDetailsDialog();
      this.setBike(response);
    },

    handleOpenEditBikeDetails(bike) {
      this.setBike(bike);
      this.showEditBikeDetailsDialog();
    },

    async handleQrCodeClick(dockStation) {
      let dockStationObject = await this.getDock(dockStation.id);

      if (dockStationObject) {
        this.setQrCode(dockStationObject.bays[0].qr_code);
        this.showQrCodeDialog();
      }
    },
    
    openInNewTab(id) {
      let route = this.$router.resolve({path: `customers/${id}`});
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.unlock-warning {
  display: block;

  i {
    font-size: 35px;
    color: $warning;
  }
}

.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }
}
</style>
