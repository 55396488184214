<template lang="pug">
  .view-ride-details
    .col
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-button.el-icon-refresh-left.cursor-pointer(
              style="font-size: 25px; margin-top: -14px;"
              :loading="loading"
              @click="reloadData(tracker)")
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span.text-underline {{ $t('trackers.serial_number') }}
        el-col
          span(v-if="tracker.mac") {{ tracker.mac }}/ {{ tracker.imei }}
          span(v-if="tracker.dev_eui") {{ tracker.dev_eui }}
          span(v-if="!tracker.mac && !tracker.dev_eui") -
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span {{$t('bikes-map.docking_station')}}
          br
          span {{$t('bikes-map.bike-serial-number')}}
          br
          span {{$t('bikes-map.bike-rfid-code')}}
          br
          span {{$t('trackers.hw_version')}}
          br
          span {{$t('bikes-map.bike-state-of-charge')}}
        el-col
          span(v-if="tracker.bike && tracker.bike.docking_station") {{ tracker.bike.docking_station.serial_number }}-
          span(v-if="tracker.bike && tracker.bike.bay_number") {{ tracker.bike.bay_number.number}}
          span(v-else) -
          br
          span(v-if="tracker.bike") {{ tracker.bike.serial_number }}
          span(v-else) -
          br
          span(v-if="tracker.bike") {{ tracker.bike.rfid }}
          span(v-else) -
          br
          span(v-if="tracker.bike") {{ handleBikeType(tracker.bike.bike_type) }}
          span(v-else) -
          br
          span(v-if="tracker.bike") {{ $t(`${handleBikeStateOfCharge(tracker.bike.state_of_charge)}`) }}
          span(v-else) -
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )    
        el-col
          span.text-underline {{ $t('trackers.fw_version') }}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.about") {{ computeFWVerionDateFormat(tracker.tracker_latest_info.about.fw_ver) }}
          span(v-else) -
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span.text-underline {{ $t('trackers.last_location') }}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.location") {{tracker.tracker_latest_info.location.latitude }}, {{tracker.tracker_latest_info.location.longitude}}
          span(v-else) -
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span {{$t('trackers.time_of_create')}}
          br
          span {{$t('trackers.time_of_report')}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.location")
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.location.added_at) }}
            br
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.location.reported_at) }}
          span(v-else)
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span.text-underline {{ $t('trackers.last_power')}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.power") V-in: {{tracker.tracker_latest_info.power.v_in /1000.0 }}V, V-bat: {{tracker.tracker_latest_info.power.v_bat /1000.0 }}V
          span(v-else) -
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span {{$t('trackers.time_of_create')}}
          br
          span {{$t('trackers.time_of_report')}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.power")
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.power.added_at) }}
            br
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.power.reported_at) }}
          span(v-else)
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span.text-underline {{ $t('trackers.last_connection') + " GSM"}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.connection_gsm") {{ tracker.tracker_latest_info.connection_gsm.operator + ", " + tracker.tracker_latest_info.connection_gsm.ip}}
          span(v-else) -
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span {{$t('trackers.time_of_create')}}
          br
          span {{$t('trackers.time_of_report')}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.connection_gsm")
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.connection_gsm.added_at) }}
            br
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.connection_gsm.reported_at) }}
          span(v-else)
      br
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span.text-underline {{ $t('trackers.last_connection') + " Wifi"}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.connection_wifi") {{ tracker.tracker_latest_info.connection_wifi.ssid + ", " + tracker.tracker_latest_info.connection_wifi.ip }}
          span(v-else) -
      el-row.center.details-bold(
        type="flex"
        justify="center"
      )
        el-col
          span {{$t('trackers.time_of_create')}}
          br
          span {{$t('trackers.time_of_report')}}
        el-col
          span(v-if="tracker.tracker_latest_info && tracker.tracker_latest_info.connection_wifi")
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.connection_wifi.added_at) }}
            br
            span {{computeTrackerDateFormat(tracker.tracker_latest_info.connection_wifi.reported_at) }}
          span(v-else)
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";

export default {
  name: "ViewTrackerDialog",
  mixins: [filterUtils],

  computed: {
    ...mapState("tracker", ["tracker"]),
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    ...mapMutations("dialog", ["hideViewRideDetailsDialog"]),
    ...mapMutations("tracker", ["setTracker"]),
    ...mapActions("tracker", ["getTracker"]),

    async reloadData(tracker){
      this.loading = true;
      const response =  await this.getTracker(tracker.id);
      this.setTracker(response.data);
      this.loading = false;
    },

    handleBikeStateOfCharge(state_of_charge){
      if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
      if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
      if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
      return 'bikes.state_of_charge.undefined'
    },

    handleBikeType(type) {
      if (type == "mechanical") return 'Mecanică'
      else if (type == "electrical") return 'Electrică'
    }
  },
};
</script>

<style lang="scss" scoped>
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
      overflow-wrap: break-word;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
